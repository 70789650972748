import React from "react";
import { Redirect } from "react-router";

import * as routeConstant from "constant/route";

import DynamicImport from "common/dynamicImport/";

const SplashComponent = (props) => (
  <DynamicImport load={() => import("modules/splash/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const EmptyComponent = (props) => (
  <DynamicImport load={() => import("modules/empty/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const HelloComponent = (props) => (
  <DynamicImport load={() => import("modules/hello/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const ErrorComponent = (props) => (
  <DynamicImport load={() => import("modules/error/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const MaintainComponent = (props) => (
  <DynamicImport load={() => import("modules/maintain/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const AuthenticationComponent = (props) => (
  <DynamicImport load={() => import("../modules/authentication/components")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);
const VerifyEmailComponent = (props) => (
  <DynamicImport load={() => import("../modules/verify-email")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);
const MainComponent = (props) => (
  <DynamicImport load={() => import("modules/main/")}>
    {(Component) => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
);

const AppRouter = [
  {
    path: routeConstant.ROUTE_NAME_SPLASH,
    exact: true,
    component: SplashComponent,
  },
  {
    path: routeConstant.ROUTE_NAME_EMPTY,
    exact: true,
    component: EmptyComponent,
  },
  {
    path: routeConstant.ROUTE_NAME_HELLO,
    exact: true,
    component: HelloComponent,
  },
  {
    path: routeConstant.ROUTE_NAME_VERIFY_EMAIL,
    exact: true,
    component: VerifyEmailComponent,
  },
  {
    path: routeConstant.ROUTE_NAME_ERROR,
    exact: true,
    component: ErrorComponent,
  },
  {
    path: routeConstant.ROUTE_NAME_MAINTAIN,
    exact: true,
    component: MaintainComponent,
  },
  {
    path: `/${routeConstant.ROUTE_NAME_AUTHENTICATION}/:page(${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNIN}|${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNUP}|${routeConstant.ROUTE_NAME_AUTHENTICATION_FORGOT}|${routeConstant.ROUTE_NAME_AUTHENTICATION_RESET_PASSWORD})/:reset_token?`,
    exact: true,
    component: AuthenticationComponent,
  },
  {
    path: `/${routeConstant.ROUTE_NAME_MAIN}`,
    exact: false,
    component: MainComponent,
  },
  {
    path: "*",
    component: () => <Redirect to={routeConstant.ROUTE_NAME_SPLASH} />,
  },
];

export default AppRouter;
