import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import appConfigs from 'config'

import appStateReducers from 'store/appState/reducers'
import {
    changeAppStateInitializedEpic,
    changeAppStateAuthenticationModeEpic
} from 'store/appState/epics'

import languageReducers from 'store/language/reducers'
import { changeLanguageEpic } from 'store/language/epics'

import themeReducers from 'store/theme/reducers'
import { changeThemeEpic } from 'store/theme/epics'

import applicationReducers from 'store/application/reducers'
import { changeApplicationDataEpic } from 'store/application/epics'

import authenticationReducers from 'store/authentication/reducers'
import { changeAuthenticatedDataEpic } from 'store/authentication/epics'

const epicMiddleware = createEpicMiddleware()

const configureStore = () => {
    const store = createStore(
        combineReducers({
            appState: appStateReducers,
            appLanguage: languageReducers,
            appTheme: themeReducers,
            appApplication: applicationReducers,
            appAuthentication: authenticationReducers
        }),
        applyMiddleware(
            createLogger({ predicate: () => appConfigs.LOGGER.REDUX }),
            epicMiddleware
        )
    )

    epicMiddleware.run(combineEpics(
        changeAppStateInitializedEpic,
        changeAppStateAuthenticationModeEpic,
        changeLanguageEpic,
        changeThemeEpic,
        changeApplicationDataEpic,
        changeAuthenticatedDataEpic
    ))

    return store
}

export default configureStore