import { filter, map } from "rxjs/operators";

import * as actionTypes from "./actionTypes";

export const changeAuthenticatedDataEpic = (action$) =>
  action$.pipe(
    filter(
      (action) => action.type === actionTypes.CHANGE_AUTHENTICATED_DATA_CALL
    ),
    map((action) => ({
      type: actionTypes.CHANGE_AUTHENTICATED_DATA_RETURN,
      payload: { ...action.payload },
    }))
  );

export const updateUserInfoEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === actionTypes.UPDATE_NAME_USER_CALL),
    map((action) => ({
      type: actionTypes.UPDATE_NAME_USER_RETURN,
      payload: { ...action.payload },
    }))
  );
