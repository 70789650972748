export const ROUTE_NAME_SPLASH = "/";
export const ROUTE_NAME_EMPTY = "/empty";
export const ROUTE_NAME_HELLO = "/hello";
export const ROUTE_NAME_ERROR = "/error";
export const ROUTE_NAME_MAINTAIN = "/maintain";
export const ROUTE_NAME_VERIFY_EMAIL = "/verify-email";

export const ROUTE_NAME_AUTHENTICATION = "authentication";
export const ROUTE_NAME_AUTHENTICATION_SIGNIN = "signin";
export const ROUTE_NAME_AUTHENTICATION_SIGNUP = "signup";
export const ROUTE_NAME_AUTHENTICATION_FORGOT = "forgot";
export const ROUTE_NAME_AUTHENTICATION_RESET_PASSWORD = "resetpassword";

export const ROUTE_NAME_MAIN = "main";
export const ROUTE_NAME_MAIN_DASHBOARD = "dashboard";

export const ROUTE_NAME_MAIN_APPLICATION = "application";
export const ROUTE_NAME_MAIN_APPLICATION_LIST = "list";

export const ROUTE_NAME_MAIN_USER = "user";
export const ROUTE_NAME_MAIN_USER_LIST = "list";

export const ROUTE_NAME_MAIN_QUESTION_BANK = "question-bank";
export const ROUTE_NAME_MAIN_QUESTION_BANK_LIST = "list";
export const ROUTE_NAME_MAIN_QUESTION_BANK_QUESTIONS = "questions";

export const ROUTE_NAME_MAIN_TEST = "test";
export const ROUTE_NAME_MAIN_TEST_LIST = "list";
export const ROUTE_NAME_MAIN_TEST_FORM = "form";
export const ROUTE_NAME_MAIN_TEST_REPORT = "report";
export const ROUTE_NAME_MAIN_TEST_USER = "user";
export const ROUTE_NAME_MAIN_TEST_RESULT = "result";
export const ROUTE_NAME_MAIN_TEST_REVIEW = "review";
export const ROUTE_NAME_MAIN_TEST_PREPARE = "prepare";
export const ROUTE_NAME_MAIN_TEST_TAKE = "take";
export const ROUTE_NAME_MAIN_TEST_DONE = "done";
export const ROUTE_NAME_MAIN_TEST_THANK = "thank";
export const ROUTE_NAME_MAIN_TEST_CATEGORY = "category";
