import './coreConfig'

import React from 'react';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';

import configureStore from './store';

import AccessControl from './accessControl'

import appRoutes from './router';

import Style from '../styles/';

import Scroll from 'helper/scroll'

const store = configureStore();
const history = createBrowserHistory();
const accessControl = new AccessControl(store, history)
accessControl.checkRoute(history.location.pathname)

const mapStateToProps = (state) => ({
    theme: state.appTheme.current
});
const mapDispatchToProps = (dispatch) => ({

});
const AppContentWS = connect(mapStateToProps, mapDispatchToProps)(
    class AppContent extends React.Component {
        constructor(props) {
            super(props)
        }

        render() {
            return (
                <>
                    <style>{Style[this.props.theme]}</style>
                    {renderRoutes(appRoutes)}
                </>
            );
        }
    }
);

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.unlistenRouterChanged = null;
    };

    componentDidMount() {
        this.unlistenRouterChanged = history.listen((location, action) => {
            accessControl.checkRoute(location.pathname)
            Scroll.scrollTop('#root', 0)
        });
        Scroll.init('#root')
    };
    componentWillUnmount() {
        if (this.unlistenRouterChanged) {
            this.unlistenRouterChanged();
        }
    };

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <AppContentWS />
                </Router>
            </Provider>
        );
    };
};

export default Root;