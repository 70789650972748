import * as actionTypes from './actionTypes'

const initalState = {
    current: null
}

const applicationReducers = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_APPLICATION_DATA_RETURN:
            return { current: action.payload.newApplicationData }
        default:
            return { ...state }
    }
}

export default applicationReducers