import { filter, map } from 'rxjs/operators'

import * as actionTypes from './actionTypes'

export const changeAppStateInitializedEpic = action$ => (
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_INITIALIZED_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_INITIALIZED_RETURN,
      payload: { ...action.payload }
    }))
  )
)

export const changeAppStateAuthenticationModeEpic = action$ => (
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_AUTHENTICATION_MODE_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_AUTHENTICATION_MODE_RETURN,
      payload: { ...action.payload }
    }))
  )
)

