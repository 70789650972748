import * as actionTypes from "./actionTypes";

const initalState = {
  current: null,
};

const authenticationReducers = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_AUTHENTICATED_DATA_RETURN:
      return { current: action.payload.newAuthenticatedData };
    case actionTypes.UPDATE_NAME_USER_RETURN:
      const { first_name, last_name, avatar } = action.payload.data.inputs;
      const { load } = action.payload.data;
      return {
        ...state,
        current: {
          ...state.current,
          user: {
            ...state.current.user,
            first_name: first_name,
            last_name: last_name,
            avatar: avatar,
          },
        },
        load: load
      };
    default:
      return { ...state };
  }
};

export default authenticationReducers;
