import * as appStateConstant from 'constant/appState'
import * as actionTypes from './actionTypes'

const initalState = {
  initialized: appStateConstant.APP_STATE_INITIALIZED_NO,
  authenticationMode: appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
}

const appStateReducers = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_APP_STATE_INITIALIZED_RETURN:
      return { ...state, initialized: action.payload.newAppStateInitialized }
    case actionTypes.CHANGE_APP_STATE_AUTHENTICATION_MODE_RETURN:
      return { ...state, authenticationMode: action.payload.newAppStateAuthenticatonMode }
    default:
      return { ...state }
  }
}

export default appStateReducers
