export const TYPE_SYSADMIN = "SYSADMIN";
export const TYPE_ADMIN = "ADMIN";
export const TYPE_REVIEWER = "REVIEWER";
export const TYPE_LEAD_REVIEWER = "LEAD_REVIEWER";
export const TYPE_EXAMINEE = "EXAMINEE";

export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_INACTIVE = "INACTIVE";
export const STATUS_WAIT_APPROVAL = "WAIT_APPROVAL";


