export const APP_STATE_INITIALIZED_YES = 'APP_STATE_INITIALIZED_YES'
export const APP_STATE_INITIALIZED_NO = 'APP_STATE_INITIALIZED_NO'

export const APP_STATE_AUTHENTICATION_MODE_TOKEN = 'APP_STATE_AUTHENTICATION_MODE_TOKEN'
export const APP_STATE_AUTHENTICATION_MODE_APPLICATION = 'APP_STATE_AUTHENTICATION_MODE_APPLICATION'
export const APP_STATE_AUTHENTICATION_MODE_APPLICATION_FIELD_APP_ID = 'app_id'
export const APP_STATE_AUTHENTICATION_MODE_APPLICATION_FIELD_APP_TOKEN = 'app_token'
export const APP_STATE_AUTHENTICATION_MODE_CODE = 'APP_STATE_AUTHENTICATION_MODE_CODE'
export const APP_STATE_AUTHENTICATION_MODE_CODE_FIELD = 'code'
export const APP_STATE_AUTHENTICATION_MODE_GUEST = 'APP_STATE_AUTHENTICATION_MODE_GUEST'
export const APP_STATE_AUTHENTICATION_MODE_GUEST_FIELD = 'guest'
