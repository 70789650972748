import * as appStateConstant from "constant/appState";
import * as routeConstant from "constant/route";
import * as userConstant from "constant/user";
import * as cookiesConstant from "constant/cookies";

import Cookies from "helper/cookies";

class AccessControl {
  constructor(store, browserHistory) {
    this.store = store;
    this.browserHistory = browserHistory;
  }

  routeToDefault(state) {
    if (
      state.appState.authenticationMode ===
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      this.browserHistory.replace(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}`
      );
    } else {
      this.browserHistory.replace(routeConstant.ROUTE_NAME_HELLO);
    }
  }
  routeToAuthentication(state) {
    if (
      state.appState.authenticationMode ===
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      this.browserHistory.replace(
        `/${routeConstant.ROUTE_NAME_AUTHENTICATION}/${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNIN}`
      );
    } else {
      this.browserHistory.replace(routeConstant.ROUTE_NAME_HELLO);
    }
  }

  hasAccessMain(state, pathname) {
    return state.appAuthentication.current;
  }
  hasAccessAuthentication(state, pathname) {
    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }
    return !state.appAuthentication.current;
  }
  hasAccessDashboard(state, pathname) {
    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }
    return true;
  }
  hasAccessApplication(state, pathname) {
    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }

    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_APPLICATION_LIST}`) >
      -1
    ) {
      return (
        [userConstant.TYPE_SYSADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    return true;
  }
  hasAccessUser(state, pathname) {
    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }

    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_USER_LIST}`) > -1) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    return true;
  }
  hasAccessQuestionBank(state, pathname) {
    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_QUESTION_BANK_LIST}`) >
      -1
    ) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    return true;
  }
  hasAccessTest(state, pathname) {
    if (
      state.appState.authenticationMode ===
        appStateConstant.APP_STATE_AUTHENTICATION_MODE_GUEST &&
      (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_PREPARE}`) >
        -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_TAKE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_DONE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_THANK}`) > -1)
    ) {
      return true;
    }

    if (
      state.appState.authenticationMode ===
        appStateConstant.APP_STATE_AUTHENTICATION_MODE_APPLICATION &&
      (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_PREPARE}`) >
        -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_TAKE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_DONE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_THANK}`) > -1)
    ) {
      return true;
    }

    if (
      state.appState.authenticationMode ===
        appStateConstant.APP_STATE_AUTHENTICATION_MODE_CODE &&
      (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_PREPARE}`) >
        -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_TAKE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_DONE}`) > -1 ||
        pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_THANK}`) > -1)
    ) {
      return true;
    }

    if (
      state.appState.authenticationMode !==
      appStateConstant.APP_STATE_AUTHENTICATION_MODE_TOKEN
    ) {
      return false;
    }

    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_LIST}`) > -1) {
      return (
        [
          userConstant.TYPE_SYSADMIN,
          userConstant.TYPE_ADMIN,
          userConstant.TYPE_REVIEWER,
          userConstant.TYPE_LEAD_REVIEWER,
        ].indexOf(state.appAuthentication.current.user.type) > -1
      );
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_FORM}`) > -1) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_REPORT}`) > -1
    ) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_USER}`) > -1) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_RESULT}`) > -1
    ) {
      return (
        [
          userConstant.TYPE_SYSADMIN,
          userConstant.TYPE_ADMIN,
          userConstant.TYPE_REVIEWER,
          userConstant.TYPE_LEAD_REVIEWER,
        ].indexOf(state.appAuthentication.current.user.type) > -1
      );
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_REVIEW}`) > -1
    ) {
      return (
        [
          userConstant.TYPE_SYSADMIN,
          userConstant.TYPE_ADMIN,
          userConstant.TYPE_REVIEWER,
          userConstant.TYPE_LEAD_REVIEWER,
        ].indexOf(state.appAuthentication.current.user.type) > -1
      );
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_PREPARE}`) > -1
    ) {
      return (
        [userConstant.TYPE_EXAMINEE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_TAKE}`) > -1) {
      return (
        [
          userConstant.TYPE_EXAMINEE,
          userConstant.TYPE_ADMIN,
          userConstant.TYPE_SYSADMIN,
        ].indexOf(state.appAuthentication.current.user.type) > -1
      );
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_DONE}`) > -1) {
      return (
        [userConstant.TYPE_EXAMINEE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_THANK}`) > -1) {
      return (
        [userConstant.TYPE_EXAMINEE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_TEST_CATEGORY}`) > -1
    ) {
      return (
        [userConstant.TYPE_SYSADMIN, userConstant.TYPE_ADMIN].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      );
    }
    return true;
  }

  checkRoute(pathname) {
    if (
      window.location.hostname !== "localhost" &&
      window.location.protocol !== "https:"
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
      return;
    }

    const state = this.store.getState();

    if (
      pathname !== routeConstant.ROUTE_NAME_SPLASH &&
      state.appState.initialized !== appStateConstant.APP_STATE_INITIALIZED_YES
    ) {
      Cookies.set(cookiesConstant.COOKIES_KEY_INITIAL_URL, pathname, {
        secure: true,
        sameSite: "None",
      });
      setTimeout(() => {
        this.browserHistory.replace(
          `${routeConstant.ROUTE_NAME_SPLASH}${
            window.location.search ? window.location.search : ""
          }`
        );
      }, 0);
      return;
    }

    if (
      pathname.indexOf(routeConstant.ROUTE_NAME_AUTHENTICATION) > -1 &&
      !this.hasAccessAuthentication(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
    if (
      pathname.indexOf(routeConstant.ROUTE_NAME_MAIN) > -1 &&
      !this.hasAccessMain(state, pathname)
    ) {
      this.routeToAuthentication(state);
      return;
    }

    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}`
      ) > -1 &&
      !this.hasAccessDashboard(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_APPLICATION}`
      ) > -1 &&
      !this.hasAccessApplication(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_USER}`
      ) > -1 &&
      !this.hasAccessUser(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_QUESTION_BANK}`
      ) > -1 &&
      !this.hasAccessQuestionBank(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_TEST}`
      ) > -1 &&
      !this.hasAccessTest(state, pathname)
    ) {
      this.routeToDefault(state);
      return;
    }
  }
}

export default AccessControl;
